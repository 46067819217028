/*******************************
        User Overrides
*******************************/

.ui.dropdown {
  font-size: 1.4rem;
  height: @heightInput;
}

.ui.dropdown > .dropdown.icon {
  color: @blue;
  font-size: 1.4rem;

  &::before {
   font-family: 'Icons';
   content: "\f078" !important;
  }
}

.ui.dropdown .menu .active.item,
.ui.dropdown .menu .selected.item {
  &:hover {
    background: @blue-light;
  }
}

.ui.dropdown .menu .selected.item {
  &:hover {
    color: @blue;
  }
}

.ui.selection.dropdown {
  line-height: inherit;
}

.ui.selection.dropdown > .dropdown.icon {
  height: 100%;
  display: flex;
  align-items: center;
  right: 1.5*@selectionHorizontalPadding;
}

.ui.selection.dropdown.error.visible > .text.default {
  color: @textColor;
}
.ui.selection.dropdown.error.visible > .text:not(.default) {
  color: @negativeColor;
}
.ui.dropdown.error > .menu > .item:hover {
  background-color: @hoveredItemBackground;
}
.ui.selection.active.dropdown.error .menu {
  border-color: @errorBorderColor;
}
.ui.dropdown.error > .text {
  color: @negativeColor;
}
.ui.dropdown.error > .dropdown.icon {
  color: @negativeColor;
}
.ui.dropdown.disabled > .dropdown.icon {
  color: @grey;
}

.ui.disabled.dropdown,
.ui.dropdown .menu > .disabled.item {
  background-color: @grey-light;
}

.ui.search.selection.dropdown > input.search {
  color: @textColor;
  font-weight: bold;
}

@media only screen and (max-width : @largestMobileScreen) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: @maxHeight;
  }
}
@media only screen and (min-width: @tabletBreakpoint) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: @maxHeight;
  }
}
@media only screen and (min-width: @computerBreakpoint) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: @maxHeight;
  }
}
@media only screen and (min-width: @widescreenMonitorBreakpoint) {
  .ui.scrolling.dropdown .menu,
  .ui.dropdown .scrolling.menu {
    max-height: @maxHeight;
  }
}
/*******************************
         Site Overrides
*******************************/

.ui.checkbox.disabled {
  opacity: 0.35;
  label::before {
    background-color: @grey;
    border-color: darken(@grey, 40);
  }
}
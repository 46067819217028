/*******************************
         Site Overrides
*******************************/

.ui.table thead {
  font-size: 1.2rem;
}

.ui.sortable.table thead th {
  border-left: transparent;
}

.ui.table thead th {
  border-bottom: 4px solid @blue-light;
}